<template>
  <div class="mb-4">
    <ul class="nav nav-pills nav-justified">
      <li class="nav-item waves-effect waves-light">
        <router-link
          class="nav-link"
          :class="{ 
            active: seccion == 'acerca-de'
          }"
          :to="{ name: 'acercaDe' }"
        >
          Acerca de
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light">
        <router-link
          :to="{ name: 'registroActualizacion' }"
          class="nav-link"
          :class="{ 
            active: seccion == 'registro-actualizaciones'
          }"
        >
          <span class="d-block d-sm-none">
            Registro
          </span>
          <span class="d-none d-sm-block">
            Registro de actualizaciones
          </span>
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light" v-if="false">
        <router-link
          :to="{ name: 'certificadoLicencia' }"
          v-if="false"
          class="nav-link"
          :class="{ 
            active: seccion == 'certificado-licencia'
          }"
        >
          <span class="d-block d-sm-none">
            Certificado
          </span>
          <span class="d-none d-sm-block">
            Certificado de licencia
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['seccion']
}
</script>
