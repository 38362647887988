<template>
  <Layout :tituloPagina="'Sistema | Acerca De'">
    <div id="acercaDe" >
      <div class="row">
        <div class="col-lg-5">
          <cmp-secciones seccion="acerca-de"></cmp-secciones>
        </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-md-4 offset-md-4 text-center">
          <div class="auth-logo">
            <img
              :src="logoArgus"
              alt="Logo argusblack"
              class="auth-logo-dark"
              style="width:55%;"
            />
            <img
              :src="logoArgusBlanco"
              alt="Logo argusblack"
              class="auth-logo-light"
              style="width:55%;"
            />
          </div>
          <br /><br />
          <h1 class="display-2">ArgusBlack</h1>
          <br /><br />
          <p>
            Sistema desarrollado por
            <a href="http://livaur.com">
              Livaur S de RL de CV
            </a>
            para automatizar las redes de proveedores de internet.
          </p>
        </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="accordion accordion-flush" id="acercaDe">
            <div class="accordion-item">
              <h2 class="accordion-header" id="acerca">
                <button
                  class="accordion-button fw-medium collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#ultimoRegistro"
                  aria-expanded="false"
                  aria-controls="ultimoRegistro"
                >
                  <strong>Versión {{ registro.version }}</strong>
                  <small>{{formatoFecha(registro.created_at)}}</small>
                </button>
              </h2>
              <div
                id="ultimoRegistro"
                class="accordion-collapse collapse hidden"
                aria-labelledby="acerca"
                data-bs-parent="#acercaDe"
              >
                <div class="accordion-body">
                  <div class="text-muted">
                    <span v-if="!atributoVacio(registro.actualizaciones_seguridad)">
                      <b>Actualizaciones de seguridad:</b>
                      <pre>{{ registro.actualizaciones_seguridad }}</pre>
                    </span>
                    <span v-if="!atributoVacio(registro.correcciones_bugs)">
                      <b>Corrección de bugs:</b>
                      <pre>{{ registro.correcciones_bugs }}</pre>
                    </span>
                    <span v-if="!atributoVacio(registro.mejoras)">
                      <b>Mejoras:</b>
                      <pre class="seventh">{{ registro.mejoras }}</pre>
                    </span>
                    <span v-if="!atributoVacio(registro.ultimas_novedades)">
                      <b>Últimas novedades:</b>
                      <pre>{{ registro.ultimas_novedades }}</pre>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>

import Layout from "@/views/layouts/main.vue"
import RegistroActualizacionesSistemaSrv from '@/services/RegistroActualizacionesSistemaSrv.js'
import logoArgus from '@/assets/img/logoArgus.png'
import logoArgusBlanco from '@/assets/img/logoArgusBlanco.png'
import CmpSecciones from './CmpSecciones.vue'
import moment from 'moment'
export default {
  name: 'AcercaDe',

  components:{
    Layout,
    CmpSecciones
  },
  data: function() {
    return {
      logoArgus: logoArgus,
      logoArgusBlanco: logoArgusBlanco,
      registro: {
        id: null,
        version: '',
        actualizaciones_recientes: '',
        actualizaciones_seguridad: '',
        correcciones_bugs: '',
        mejoras: '',
        ultimas_novedades: ''
      }
    }
  },
  created: function(){
    var self = this

    self.cargarActualVersion()
  },
  methods: {
    atributoVacio: function(atributo) {
      if (atributo == '' || atributo == null || atributo == undefined)
        return true
      return false
    },
    
    cargarActualVersion: function(){
      var self = this

      RegistroActualizacionesSistemaSrv.actualJSON().then(response => {
        self.registro = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message 
        } catch (error) {
          mensaje ='No se cargo correctamente el registro actual'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.$router.go(-1)
    },

    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },
  }
}
</script>

<style scoped>
pre {
  overflow-x: auto;
  border: none;
  display: block;
  font-family: Arial, Helvetica, sans-serif, "Helvetica Neue", Helvetica;
  text-align: justify;
  background: none;
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
small {
  position: absolute;
  right: 51px;
}
</style>